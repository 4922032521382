import { Box, Flex, MediaBox, Divider } from "@jewlr/storybook/core"
import loadable from "@loadable/component"
import React, { useEffect, useRef } from "react"
import { Helmet } from "react-helmet-async"
import { useSelector } from "react-redux"

import useCollection from "areas/collection/hoc/use-collection"
import IntersectionWrapper from "components/common/intersection-wrapper"
import Perks from "components/perks"
import { submit_ga_tracker } from "helpers/analytics-helpers"
import { IS_JEWLR, isTestMode, retry } from "helpers/application"
import { logCurrentPageInCollectionStats } from "helpers/collection"
import useScrollRestoration from "helpers/use-scroll-restoration"
import fetchWishlist from "hoc/fetch-wishlist"

import Banner from "./banner"
import FilterAccordion from "./filter-accordion"
import FilterHeader from "./filter-header"
import Heading from "./heading"
import LoadMoreProducts from "./load-more-products"
import FilterAccordionLoading from "./loaders/filter-accordion-loading"
import Products from "./products"
import SortMenu from "./sort-menu"
import SuperFilters from "./super-filters"

const CollectionDescription = loadable(
  () => retry(() => import("./collection-description")),
  { ssr: false }
)
const Slider = loadable(() => retry(() => import("./slider")), { ssr: false })
const FilterMobile = loadable(() => retry(() => import("./filter-mobile")), {
  ssr: false,
})
const FilterTags = loadable(() => retry(() => import("./filter-tags")), {
  ssr: false,
})

const Collection = () => {
  const productAreaRef = useRef()
  const newPageFocusRef = useRef()
  const loading = useSelector((state) => state.collection.loading)

  const { data, hasNextPage } = useCollection()
  const mainBanners = data?.banners?.filter((b) => b.location === "main") || []

  useScrollRestoration()

  useEffect(() => {
    if (data) {
      logCurrentPageInCollectionStats(data?.mountedPath)

      if (data.unique_event_id) {
        window.cache_free_ssr_data.unique_event_ids.collection =
          data.unique_event_id
      }

      if (!isTestMode) {
        dataLayer.push({
          collectionTitle: data?.title,
          contentIds: data?.product_result
            ?.slice(0, 9)
            ?.map((p) => p.rm_style_code),
          contentName: data?.mountedPath,
          contentType: "product",
          event: "view-collection",
          viewCollectionUniqueEventId:
            window.cache_free_ssr_data.unique_event_ids.collection,
        })

        dataLayer.push({
          event: "google-remarketing",
          googleRemarketing: {
            ecomm_category:
              data.title == "Search"
                ? null
                : data.filter_row.clear_all_link.replace("/", ""),
            ecomm_pagetype:
              data.title == "Search" ? "searchresults" : "category",
            ecomm_prodid: data.product_result.map((p) => p.style_code),
            ecomm_totalvalue: data.product_result.reduce((total, p) => {
              if (p.looks && p.default_look && p.looks[p.default_look]) {
                return total + (p.looks[p.default_look].selling || 0)
              } else {
                return total
              }
            }, 0),
          },
        })

        if (data.url_path === "search") {
          dataLayer.push({
            contentIds: data.product_result
              .slice(0, 9)
              .map((p) => p.rm_style_code),
            contentType: "product",
            event: "search",
            searchString: data.preserve_cat_path,
          })
          submit_ga_tracker(`/search?k=${data.preserve_cat_path}`)
        } else if (data.pagination?.current_page) {
          submit_ga_tracker(`/${data.pagination.current_page}`)
        }
      }
    }
  }, [])

  return (
    <>
      <Helmet encodeSpecialCharacters={false}>
        {/* eslint-disable react/jsx-sort-props, react/no-unknown-property */}
        {data.default_collection_image_url && (
          <meta
            content={data.default_collection_image_url}
            name="og_image"
            property="og:image"
          />
        )}
        {mainBanners.length > 0 &&
          (mainBanners[0].image?.srcSet || []).map((srcset, i) => (
            <link
              key={`collection-banner-preload-${
                mainBanners[0].image?.id || i
              }-${srcset.media}`}
              rel="preload"
              fetchPriority="high"
              as="image"
              imageSrcSet={srcset.srcSet}
              imageSizes={srcset.sizes}
              media={srcset.media}
            />
          ))}
        {/* eslint-enable react/jsx-sort-props, react/no-unknown-property */}
      </Helmet>
      {mainBanners.length > 0 && (
        <Box data-optimize="collection-banner">
          <Banner firstElement lazyload={false} medias={mainBanners} />
        </Box>
      )}
      <Box containWide px={2}>
        <Heading />
        {IS_JEWLR() && (
          <MediaBox lessThan="desktop">
            <SuperFilters />
            <FilterTags />
          </MediaBox>
        )}
        <Divider
          bg="grey.10"
          display={{ _: "none", desktop: "block" }}
          mb="10px"
        />
        <div id="collection-scroll-back-point">
          {IS_JEWLR() && (
            <MediaBox lessThan="desktop">
              <FilterHeader />
            </MediaBox>
          )}
          <Flex justifyContent="center">
            {IS_JEWLR() &&
              (loading === "full" ? (
                <FilterAccordionLoading />
              ) : (
                <MediaBox
                  aria-label="Filters"
                  as="nav"
                  greaterThanOrEqual="desktop"
                  maxWidth="215px"
                  mr={4}
                  width="22%"
                >
                  <FilterAccordion />
                </MediaBox>
              ))}

            <Box flex="1">
              <Box pt={IS_JEWLR() ? { _: 3, desktop: 0 } : 0}>
                <MediaBox lessThan="desktop">
                  <FilterMobile />
                </MediaBox>
                {IS_JEWLR() && (
                  <MediaBox greaterThanOrEqual="desktop">
                    <SuperFilters />
                    <Flex alignItems="center" justifyContent="end">
                      <FilterTags />
                      <SortMenu />
                    </Flex>
                    <Divider bg="grey.10" mb="10px" mt={1} />
                  </MediaBox>
                )}
                <section aria-atomic aria-live="polite" ref={productAreaRef}>
                  <Products newPageFocusRef={newPageFocusRef} />
                </section>
              </Box>
            </Box>
          </Flex>

          <Box data-cy="collection-load-product" mb="110px" mt={4}>
            {hasNextPage && (
              <LoadMoreProducts newPageFocusRef={newPageFocusRef} />
            )}
          </Box>
        </div>
      </Box>
      {IS_JEWLR() && (
        <IntersectionWrapper>
          <Slider />
          <Perks />
          <CollectionDescription />
        </IntersectionWrapper>
      )}
    </>
  )
}

export default fetchWishlist(Collection)
